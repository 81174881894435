import {
	TOGGLE_DRAWER,
	TOGGLE_TERMINAL,
	SET_LOADING,
	UNSET_LOADING,
	TOGGLE_THEME,
} from "./types";

const reducer = (state, action) => {
	switch (action.type) {
		// toggle the sidebar
		case TOGGLE_DRAWER: {
			return {
				...state,
				drawerOpen: !action.payLoad,
			};
		}

		/**
		 * This is under development and hides the terminal on scroll
		 */
		case TOGGLE_TERMINAL: {
			return {
				...state,
				terminalHidden: action.payLoad,
			};
		}

		case TOGGLE_THEME: {
			return {
				...state,
				themeColor: action.payLoad,
			};
		}

		case SET_LOADING: {
			return {
				...state,
				loading: true,
			};
		}

		case UNSET_LOADING: {
			return {
				...state,
				loading: false,
			};
		}

		default:
			return { ...state };
	}
};

export default reducer;
