import React, { useReducer } from "react";
import {
	TOGGLE_DRAWER,
	TOGGLE_TERMINAL,
	SET_LOADING,
	UNSET_LOADING,
	TOGGLE_THEME,
} from "./types";
import AppContext from "./appContext";
import AppReducer from "./appReducer";
import useTheme from "../utility/hooks/useTheme";
import { isBrowser } from "../utility/util-checkbrowser";

const AppState = props => {
	const [initTheme] = useTheme();
	const initialState = {
		themeColor: initTheme,
		drawerOpen: false,
		terminalHidden: false,
		errorMessage: "",
		loading: true,
	};

	// app state resolvers
	const [state, dispatch] = useReducer(AppReducer, initialState);

	// theming
	const [, setTheme] = useTheme();

	/**
	 * @description open or close the sidebar menu (drawer)
	 * @param {boolean} isOpen
	 */
	const toggleDrawer = isOpen => {
		dispatch({ type: TOGGLE_DRAWER, payLoad: isOpen });
	};

	/**
	 * @description enable theming in the global app state
	 * @param {string} color
	 */
	const toggleTheme = color => {
		// set className of body
		setTheme(color);
		dispatch({ type: TOGGLE_THEME, payLoad: color });
	};

	/**
	 * @description check if the terminal is shown on the page or not
	 * @param {boolean} isHidden
	 */
	const toggleTerminal = isHidden => {
		dispatch({ type: TOGGLE_TERMINAL, payLoad: isHidden });
	};

	/**
	 * @description sets the loading state to show a spinner or similar
	 */
	const setLoading = () => {
		dispatch({ type: SET_LOADING, payLoad: null });
	};

	/**
	 * @description reset the loading state
	 */
	const unsetLoading = () => {
		dispatch({ type: UNSET_LOADING, payLoad: null });
	};

	/**
	 * not really a dispatch. just sets the current ref thet has to be scrolled to
	 * @param {*} ref
	 */
	const scrollToElement = ref => {
		// just scroll to element
		isBrowser() &&
			window.scrollTo({
				top: ref.current.offsetTop,
				behavior: "smooth",
			});
	};

	return (
		<AppContext.Provider
			value={{
				themeColor: state.themeColor,
				drawerOpen: state.drawerOpen,
				terminalHidden: state.terminalHidden,
				loading: state.loading,
				toggleDrawer,
				toggleTerminal,
				toggleTheme,
				setLoading,
				unsetLoading,
				scrollToElement,
			}}
		>
			{props.children}
		</AppContext.Provider>
	);
};

export default AppState;
