import { useEffect, useState } from "react";
import { isBrowser } from "../util-checkbrowser";

const useLocalstorage = (key, init) => {
	const item = isBrowser() ? localStorage.getItem(key) : null;

	// if an item exists, take - else take init
	const [value, setValue] = useState(item || init);

	useEffect(() => {
		localStorage && localStorage.setItem(key, value);
	}, [value, key, init]);

	return [value, setValue];
};

export default useLocalstorage;
