import { useEffect } from "react";
import useLocalstorage from "./useLocalstorage";

const useTheme = () => {
	// initial value for themes
	const [theme, setTheme] = useLocalstorage("theme", "light");

	useEffect(() => {
		document.body.className = "";
		document.body.classList.add(theme);
	}, [theme]);
	return [theme, setTheme];
};

export default useTheme;
